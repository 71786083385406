
@media print {
    .footer,
    .aside,
    .py-2,
    .header{
        display: none;
    }
    div{
        width: 100%;
    }
    .main.container {
        max-width: 100%;
        width: 100%;
    }
    .table_content{
        width: 100% !important;
    }
    .foot_footer,
    .MuiTableRow-root.MuiTableRow-head.sip_header.css-1q1u3t4-MuiTableRow-root{
        display: none !important;

    }
    .action{
        display: none !important;
    }
}
